import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import $ from "jquery";
import { OutputBindingAdapter } from "../bindings/outputAdapter";
import { shinyAppBindOutput, shinyAppUnbindOutput } from "./initedMethods";
import { sendImageSizeFns } from "./sendImageSize";
var boundInputs = {};
// todo make sure allowDeferred can NOT be supplied and still work
function valueChangeCallback(inputs, binding, el, allowDeferred) {
  var id = binding.getId(el);
  if (id) {
    var value = binding.getValue(el);
    var type = binding.getType(el);
    if (type) id = id + ":" + type;
    var opts = {
      priority: allowDeferred ? "deferred" : "immediate",
      binding: binding,
      el: el
    };
    inputs.setInput(id, value, opts);
  }
}
function bindInputs(shinyCtx) {
  var scope = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : document.documentElement;
  var inputs = shinyCtx.inputs,
    inputsRate = shinyCtx.inputsRate,
    inputBindings = shinyCtx.inputBindings;
  var bindings = inputBindings.getBindings();
  var inputItems = {};
  var _loop = function _loop() {
    var binding = bindings[i].binding;
    var matches = binding.find(scope) || [];
    var _loop2 = function _loop2() {
      var el = matches[j];
      var id = binding.getId(el);

      // Check if ID is falsy, or if already bound
      if (!id || boundInputs[id]) return "continue";
      var type = binding.getType(el);
      var effectiveId = type ? id + ":" + type : id;
      inputItems[effectiveId] = {
        value: binding.getValue(el),
        opts: {
          immediate: true,
          binding: binding,
          el: el
        }
      };

      /*jshint loopfunc:true*/
      var thisCallback = function () {
        var thisBinding = binding;
        var thisEl = el;
        return function (allowDeferred) {
          valueChangeCallback(inputs, thisBinding, thisEl, allowDeferred);
        };
      }();
      binding.subscribe(el, thisCallback);
      $(el).data("shiny-input-binding", binding);
      $(el).addClass("shiny-bound-input");
      var ratePolicy = binding.getRatePolicy(el);
      if (ratePolicy !== null) {
        inputsRate.setRatePolicy(effectiveId, ratePolicy.policy, ratePolicy.delay);
      }
      boundInputs[id] = {
        binding: binding,
        node: el
      };
      $(el).trigger({
        type: "shiny:bound",
        // @ts-expect-error; Can not remove info on a established, malformed Event object
        binding: binding,
        bindingType: "input"
      });
    };
    for (var j = 0; j < matches.length; j++) {
      var _ret = _loop2();
      if (_ret === "continue") continue;
    }
  };
  for (var i = 0; i < bindings.length; i++) {
    _loop();
  }
  return inputItems;
}
function bindOutputs(_ref) {
  var sendOutputHiddenState = _ref.sendOutputHiddenState,
    maybeAddThemeObserver = _ref.maybeAddThemeObserver,
    outputBindings = _ref.outputBindings;
  var scope = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : document.documentElement;
  var $scope = $(scope);
  var bindings = outputBindings.getBindings();
  for (var i = 0; i < bindings.length; i++) {
    var binding = bindings[i].binding;
    var matches = binding.find($scope) || [];
    for (var j = 0; j < matches.length; j++) {
      var _el = matches[j];
      var id = binding.getId(_el);

      // Check if ID is falsy
      if (!id) continue;

      // In some uncommon cases, elements that are later in the
      // matches array can be removed from the document by earlier
      // iterations. See https://github.com/rstudio/shiny/issues/1399
      if (!$.contains(document.documentElement, _el)) continue;
      var $el = $(_el);
      if ($el.hasClass("shiny-bound-output")) {
        // Already bound; can happen with nested uiOutput (bindAll
        // gets called on two ancestors)
        continue;
      }

      // If this element reports its CSS styles to getCurrentOutputInfo()
      // then it should have a MutationObserver() to resend CSS if its
      // style/class attributes change. This observer should already exist
      // for _static_ UI, but not yet for _dynamic_ UI
      maybeAddThemeObserver(_el);
      var bindingAdapter = new OutputBindingAdapter(_el, binding);
      shinyAppBindOutput(id, bindingAdapter);
      $el.data("shiny-output-binding", bindingAdapter);
      $el.addClass("shiny-bound-output");
      if (!$el.attr("aria-live")) $el.attr("aria-live", "polite");
      $el.trigger({
        type: "shiny:bound",
        // @ts-expect-error; Can not remove info on a established, malformed Event object
        binding: binding,
        bindingType: "output"
      });
    }
  }

  // Send later in case DOM layout isn't final yet.
  setTimeout(sendImageSizeFns.regular, 0);
  setTimeout(sendOutputHiddenState, 0);
}
function unbindInputs() {
  var scope = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document.documentElement;
  var includeSelf = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var inputs = $(scope).find(".shiny-bound-input").toArray();
  if (includeSelf && $(scope).hasClass("shiny-bound-input")) {
    inputs.push(scope);
  }
  for (var i = 0; i < inputs.length; i++) {
    var _el2 = inputs[i];
    var binding = $(_el2).data("shiny-input-binding");
    if (!binding) continue;
    var id = binding.getId(_el2);
    $(_el2).removeClass("shiny-bound-input");
    delete boundInputs[id];
    binding.unsubscribe(_el2);
    $(_el2).trigger({
      type: "shiny:unbound",
      // @ts-expect-error; Can not remove info on a established, malformed Event object
      binding: binding,
      bindingType: "input"
    });
  }
}
function unbindOutputs(_ref2) {
  var sendOutputHiddenState = _ref2.sendOutputHiddenState;
  var scope = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : document.documentElement;
  var includeSelf = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var outputs = $(scope).find(".shiny-bound-output").toArray();
  if (includeSelf && $(scope).hasClass("shiny-bound-output")) {
    outputs.push(scope);
  }
  for (var i = 0; i < outputs.length; i++) {
    var $el = $(outputs[i]);
    var bindingAdapter = $el.data("shiny-output-binding");
    if (!bindingAdapter) continue;
    var id = bindingAdapter.binding.getId(outputs[i]);
    shinyAppUnbindOutput(id, bindingAdapter);
    $el.removeClass("shiny-bound-output");
    $el.removeData("shiny-output-binding");
    $el.trigger({
      type: "shiny:unbound",
      // @ts-expect-error; Can not remove info on a established, malformed Event object
      binding: bindingAdapter.binding,
      bindingType: "output"
    });
  }

  // Send later in case DOM layout isn't final yet.
  setTimeout(sendImageSizeFns.regular, 0);
  setTimeout(sendOutputHiddenState, 0);
}

// (Named used before TS conversion)
// eslint-disable-next-line @typescript-eslint/naming-convention
function _bindAll(shinyCtx, scope) {
  bindOutputs(shinyCtx, scope);
  return bindInputs(shinyCtx, scope);
}
function unbindAll(shinyCtx, scope) {
  var includeSelf = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  unbindInputs(scope, includeSelf);
  unbindOutputs(shinyCtx, scope, includeSelf);
}
function bindAll(shinyCtx, scope) {
  // _bindAll returns input values; it doesn't send them to the server.
  // Shiny.bindAll needs to send the values to the server.
  var currentInputItems = _bindAll(shinyCtx, scope);
  var inputs = shinyCtx.inputs;
  $.each(currentInputItems, function (name, item) {
    inputs.setInput(name, item.value, item.opts);
  });

  // Not sure if the iframe stuff is an intrinsic part of bindAll, but bindAll
  // is a convenient place to hang it. bindAll will be called anytime new HTML
  // appears that might contain inputs/outputs; it's reasonable to assume that
  // any such HTML may contain iframes as well.
  shinyCtx.initDeferredIframes();
}
export { unbindAll, bindAll, _bindAll };